import getConfig from '@beam-tech/config'

/* eslint-disable scanjs-rules/call_addEventListener */
document.addEventListener('turbolinks:load', function turboLinksLoad() {
  const form = document.getElementById('login-form')

  if (form) {
    form.addEventListener('submit', function submitForm() {
      const email = document.getElementById('login_form_email')
      const config = getConfig()
      const oauthDomains = config?.auth.email_domains
        .map(d => `@${d}`)
        .join('|')

      // eslint-disable-next-line security/detect-non-literal-regexp, security-node/non-literal-reg-expr
      const regex = new RegExp(oauthDomains)
      if (email.value.match(regex)) {
        // This was a necessary hack having to do with the Rails/JS interop
        // eslint-disable-next-line fp/no-mutation
        form.action = `/auth/${config?.auth?.provider}`
      }
    })
  }
})
