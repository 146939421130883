// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import React from 'react'
import ReactDOM from 'react-dom'
import ReactDOMClient from 'react-dom/client'
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as Activestorage from '@rails/activestorage'
import { Link as BaseRRLink } from 'react-router-dom' // If using react-router-dom

import '../channels'
import '../loginForm'

// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) Rails.start()
Turbolinks.start()
Activestorage.start()

window.React = React
window.ReactDOM = ReactDOM
window.ReactDOMClient = ReactDOMClient

const RRLink = ({ href, ...rest }) =>
  React.createElement(BaseRRLink, { to: href, ...rest })

// eslint-disable-next-line fp/no-mutation, no-undef
globalThis.LINK_COMPONENTS = {
  internal: RRLink,
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
